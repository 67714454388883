<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <b-nav-item @click="goToUserPreferences()">
        <feather-icon
            icon="SettingsIcon"
            size="21"
          />
      </b-nav-item>
      <b-nav-item @click="goToChangelogs()">
        <feather-icon
            icon="FileTextIcon"
            size="21"
          />
      </b-nav-item>  
      <dark-toggler class="d-lg-block" />
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <b-nav-item 
        @click="goToDemandesModificationPlanning()"
        v-if="$can('PLANNING_ADMIN:admin') || $can('PLANNING_SUPERADMIN:admin')"
      >
        <feather-icon
            :badge="demandesCount"
            badge-classes="bg-danger"
            icon="CalendarIcon"
            size="21"
            class="text-body"
          />
      </b-nav-item>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItem
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import { ChangelogApplication } from '@/api/models/enums/changelogApplication'
import appSettings from "@/appSettings";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    BNavItem
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    goToUserPreferences() {
        this.$router.push({ name: 'preferences-utilisateur' })
    },
    goToDemandesModificationPlanning() {
      this.$router.push({name: 'demandes-modifications-plannings'})
    },
    loadDemandes() {
      if (!this.$can('PLANNING_ADMIN:admin') && !this.$can('PLANNING_SUPERADMIN:admin')) return

      if (this.$store?.state?.user?.user?.uid) {
        this.$store.dispatch('demandes/fetchDemandes')
      }
    },
    goToChangelogs() {
      this.$router.push({ name: 'changelogs' })
    },
    async countUnreadChangelogs() {
      if (this.$store?.state?.user?.user?.uid) {
        await this.$http.myressif.unreadChangelogs
        .countUnread(ChangelogApplication.MYRESSIF, appSettings.getTenantId())
        .then((response) => {
          if (response > 0) {
            const h = this.$createElement

            var button = h(
              'button', 
              { 
                on: { 
                  click: async () => {
                    await this.$http.myressif.unreadChangelogs
                      .post(ChangelogApplication.MYRESSIF, appSettings.getTenantId())
                      .then((response) => {
                        this.$bvToast.hide("toast-changelogs");
                      })
                  } 
                }, 
                class: 'close ml-auto mb-1', 
                id: 'close-btoast-button', 
                style: "font-size: 20px; text-shadow: none; margin-bottom: 2px !important;" 
              }, 
              'x')

            const vNodesTitle = h(
              'div',
              { class: ['d-flex', 'flex-grow-1', 'align-items-baseline'] },
              [
                h('strong', { class: 'mr-2' }, 'Mise à jour du logiciel'),
                button
              ]
            )

            if (response == 1) {
              this.$bvToast.toast('Cliquer pour voir la nouvelle mise à jour.', {
                title: [vNodesTitle],
                variant: 'success',
                to: 'changelogs',
                autoHideDelay: 900000,
                noCloseButton: true,
                id: "toast-changelogs"
              })
            } else {
              this.$bvToast.toast('Cliquer pour voir les ' + response + ' nouvelles mises à jour.', {
                title: [vNodesTitle],
                variant: 'success',
                to: 'changelogs',
                autoHideDelay: 900000,
                noCloseButton: true,
                id: "toast-changelogs"
              })
            }
          }
        });
        }
    }
  },
  computed: {
    listeDemandes() {
      return this.$store.getters["demandes/demandes"]
    },

    demandesCount() {
      return this.$store.getters["demandes/demandes"] ? this.$store.getters["demandes/demandes"].length : 0
    }
  },
  async created() {
    await this.countUnreadChangelogs();
    setInterval(() => this.loadDemandes(), 300000);
    setInterval(async () => await this.countUnreadChangelogs(), 900000);
  }
}
</script>
